import React from "react";
import Page from "../../components/global/Page";
import Terms from "../../components/global/Terms";
import GeneralClauses from "../../components/global/GeneralClauses";

const TermsPage = () => {
  return (
    <Page title="Términos y Condiciones">
      <Terms />
      <GeneralClauses />
    </Page>
  );
};

export default TermsPage;
